import React, { useState, useLayoutEffect } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import Layout from 'src/layouts/BaseLayout'

import QRCodeImobiliario from './assets/images/qr_consorcio_imob.png'
import pageContext from './pageContext.json'

import Hero from './_sections/_hero/_index'
import Benefits from './_sections/_benefits/_index'
import Consortium from './_sections/_consortium/_index'
import YourPlans from './_sections/_your-plans/_index'
import YourDreams from './_sections/_your-dreams/_index'
import Blog from './_sections/_blog/_index'
import Faq from '../_section/_faq/_index'

const WIDTH_MD = 768

const Consorcio = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const [ isOpen, setIsOpen ] = useState(false)

  const domReady = useDomReady()
  const width = useWidth(300)

  const oneLink = 'https://inter-co.onelink.me/Qyu7/uo6siw4e'

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title={`Agora <a target="_blank" href="${oneLink}" class="text-orange--extra">abra nosso </a>app e contrate seu consórcio agora`}
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o app do Inter.'
        qrCode={QRCodeImobiliario}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Layout pageContext={pageContext}>
      {hubModal}
      <Hero setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <Benefits setIsOpen={setIsOpen} isMobile={isMobile} />
      <Consortium />
      <YourPlans />
      <YourDreams setIsOpen={setIsOpen} isMobile={isMobile} />
      <Blog />
      <Faq />
    </Layout>
  )
}

export default Consorcio
